export const utmParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_segment',
  'utm_content',
  'utm_term',
  'referrer'
] as const;

export type UtmParam = (typeof utmParams)[number];
